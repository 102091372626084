import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePause } from "react-icons/ai";
import { HiOutlineArrowRight } from "react-icons/hi";
import Loader from "../Shared/Loader";
import { useTranslation } from "react-i18next";
import Rating from "../../Components/Rating/rating";

const HomeItems = ({ name, items, message, loading }) => {
  const { t } = useTranslation()
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  return (
    <div className="space-y-7 text-[#444444] dark:text-white">
      <div className="flex items-center gap-2 lg:gap-4 font-[inter] text-xl">
        <AiOutlinePause className="rotate-90 w-6 h-6" />
        <p className="text-sm lg:text-xl font-semibold">{name}</p>
        <span
          className="text-[#CCD1DC]
 dark:text-[#BABABA] text-sm lg:text-xl"
        >
          |
        </span>
        <Link
          to={`/items/${name.split(" ").join("-")}`}
          state={{ items, message }}
          className="flex items-center gap-1 lg:gap-3 text-xs lg:text-base dark:text-[#BABABA]"
        >
          {t('See All')}
          <HiOutlineArrowRight />
        </Link>
      </div>
      {loading ? (
        <Loader></Loader>
      ) : items?.length > 0 ? (
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-col-6 xl:grid-cols-8 gap-5 bg-white dark:bg-secondary shadow-md rounded-[20px] min-h-[200px] p-3 xl:px-9 xl:py-0">
          {items?.slice(0, 8)?.map((item, index) => (
            <button
              key={index}
              onClick={() =>
                navigate(`/categoryItems/${item?._id}`, {
                  state: item,
                })
              }
            >
              <div className="card flex flex-col justify-center items-center space-y-2">
                <figure>
                  <img
                    src={process.env.REACT_APP_BACKEND_URL + '/' + item?.imageGallery[0]}
                    // src={item?.imageGallery?.[0]}
                    alt={item?.itemName}
                    className="h-[70px] w-[70px] md:h-[100px] md:w-[100px] object-cover rounded-lg"
                  />
                </figure>
                <div className="flex items-center space-x-2">
                    <h4 className="text-xs mt-2">
                    {item.rating ? parseFloat(item.rating).toFixed(1) : "4.0"}
                    </h4>
                    <Rating
                      rating={item.rating ? parseFloat(item.rating) : 4}
                      size={11}
                    />
                    <h4 className="text-xs  mt-2">
                      ({item.ratedBy ?? 7})
                    </h4>
                  </div>
                <div className=" text-sm font-[poppins] flex-col justify-center items-center">
                  <p className="text-[9px] xs:text-sm font-semibold">
                    {lang === "en" ? item?.itemName : item.translations.map((t) => t.lang === lang && t.itemName)}
                  </p>
                  <p className="font-[inter] flex justify-center items-center gap-1">
                    <span className="text-[10px] md:text-[14px]">
                      ₹{item?.discPrice}
                    </span>
                    <span className="text-[#9D9D9D] text-[8px] md:text-xs line-through">
                      ₹{item.price}
                    </span>
                  </p>
                </div>
              </div>
            </button>
          ))}
        </div>
      ) : (
        <div className="min-h-[200px] bg-white dark:bg-secondary shadow-md rounded-[20px] p-5 flex justify-center items-center">
          <p className="text-base xs:text-lg text-center">{message}</p>
        </div>
      )}
    </div>
  );
};

export default HomeItems;
