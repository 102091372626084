import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Loading from "../../Shared/Loading";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import NotifyModal from "../../Modals/NotifyModal";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import useProfile from "../../../Hooks/useProfile";
import { toast } from "react-toastify";
import { Disclosure } from "@headlessui/react";
import { IoChevronUpCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import ArticleAds from "../../../GoogleAdSense/ArticleAds";
import Rating from "../../../Components/Rating/rating";
// import ReactGA from 'react-ga';

const CategoryItemsCard = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const location = useLocation();
  const { categoryName } = useParams();
  const [profileData] = useProfile();
  const [selectedCategoryItems, setSelectedCategoryItems] = useState([]);
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [itemToNotify, setItemToNotify] = useState(null);
  const [notificationSent, setNotificationSent] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [originalCategoryItems, setOriginalCategoryItems] = useState([]);
  const [activeListItem, setActiveListItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  // Load favorites from local storage when the component mounts
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites"));
    if (storedFavorites) {
      setFavorites(storedFavorites);
    }
  }, []);

  function openNotifyModal(item) {
    setIsNotifyModalOpen(true);
    setItemToNotify(item);
  }

  const urlItems = `${process.env.REACT_APP_BACKEND_URL}/items?lang=${lang}`;
  const { data: items, isLoading } = useQuery([`items`, lang], () =>
    axios.get(urlItems).then((data) => {
      console.log(data);
      return data.data;
    })
  );

  // console.log(items)

  // Fetch data and update selectedCategoryItems when items are available
  useEffect(() => {
    if (!isLoading && items?.length) {
      const categoryItems = items?.filter(
        (i) => i?.category === categoryName?.split("-").join(" ")
      );
      setSelectedCategoryItems(categoryItems);
      setOriginalCategoryItems(categoryItems);
    }
  }, [categoryName, items, isLoading]);

  const handleLowToHigh = () => {
    if (!isLoading && items?.length) {
      // Sort items in ascending order based on discPrice
      const lowToHigh = originalCategoryItems
        ?.slice()
        .sort((a, b) => a?.discPrice - b?.discPrice);
      setSelectedCategoryItems(lowToHigh);
      setActiveListItem("lowToHigh");
    }
  };
  const handleHighToLow = () => {
    if (!isLoading && items?.length) {
      // Sort items in ascending order based on discPrice
      const highToLow = originalCategoryItems
        ?.slice()
        .sort((a, b) => b?.discPrice - a?.discPrice);
      setSelectedCategoryItems(highToLow);
      setActiveListItem("highToLow");
    }
  };
  const handleInStock = () => {
    if (!isLoading && items?.length) {
      const inStock = originalCategoryItems?.filter(
        (i) => i?.availability === true
      );
      setSelectedCategoryItems(inStock);
      setActiveListItem("inStock");
    }
  };

  const handleOutOfStock = () => {
    if (!isLoading && items?.length) {
      const outOfStock = originalCategoryItems?.filter(
        (i) => i?.availability === false
      );
      setSelectedCategoryItems(outOfStock);
      setActiveListItem("outOfStock");
    }
  };

  const addToFavorites = async (item) => {
    if (!favorites.some((favorite) => favorite?.itemId === item?._id)) {
      const itemObject = {
        itemId: item?._id,
        itemName: item?.itemName || "",
        imageGallery: item?.imageGallery || [],
        discPrice: item?.discPrice || 0,
        price: item?.price || 0,
        availability: item?.availability || true,
      };

      // Update local storage
      const updatedFavorites = [...favorites, itemObject];
      setFavorites(updatedFavorites);
      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
      toast.success(t("Item Added To Favourites"));

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/favourites`,
          {
            userName: profileData?.name,
            mobileNumber: profileData?.mobileNumber,
            items: [itemObject],
          }
        );
        if (res.status === 201) {
          // Handle the successful server-side addition if needed
        }
      } catch (error) {
        console.error("Error adding item to server favorites:", error);
      }
    }
  };

  const removeFromFavorites = async (item) => {
    const mobileNumber = profileData?.mobileNumber;

    // Update local storage
    const updatedFavorites = favorites?.filter(
      (favorite) => favorite.itemId !== item._id
    );
    setFavorites(updatedFavorites);
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    toast.warning(t("Item Removed To Favourites"));

    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/favourites/${mobileNumber}/${item._id}`
      );

      if (res?.status === 204) {
        // Handle the successful server-side removal if needed
      }
    } catch (error) {
      console.error("Error removing item from server favorites:", error);
    }
  };

  const dataToRender = selectedCategoryItems?.slice(startIndex, endIndex);

  if (selectedCategoryItems?.length === 0) {
    return (
      <div className="mt-32 text-center min-h-screen">No items Available</div>
    );
  }

  if (isLoading) {
    return <Loading />;
  }
  const handleItemClick = (item) => {
    // Track the item click event
    window.gtag("event", "Click", {
      event_category: "Item Click",
      event_label: item?.itemName,
    });
    // ReactGA.event({
    //   category: "Item Click",
    //   action: "Click",
    //   label: item?.itemName,
    // });
  };

  return (
    <div className="px-4 min-h-screen">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Category Products</title>
        <meta
          name="description"
          content={`Explore a curated selection of ${categoryName} products. Find the perfect ${categoryName} products to meet your needs and style at our online store.`}
        />
      </Helmet>
      <div>
        <h2 className="text-3xl mt-5 font-bold">
          {t("Category Search")} - {categoryName}
        </h2>
      </div>
      <div className="grid md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6 gap-8">
        <div className="md:col-span-1 mt-12 rounded-2xl">
          <h3 className="text-center mb-4 font-semibold text-xl ">
            {t("Product Filter")}
          </h3>
          <div className="w-full">
            <div className="mx-auto w-full rounded-2xl bg-white dark:bg-orange-500">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 dark:bg-orange-500 px-4 py-2 text-left text-md font-medium text-black dark:text-white hover:bg-green-200  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>{t("Sorting By Price")}</span>
                      <IoChevronUpCircleOutline
                        className={`${open ? "rotate-180 transform" : ""
                          } h-5 w-5 dark:text-white text-orange-500 font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 dark:bg-secondary text-sm text-gray-500">
                      <ul className="text-md cursor-pointer">
                        <li
                          onClick={handleLowToHigh}
                          className={`dark:text-gray-400 text-[16px] mb-3 ${activeListItem === "lowToHigh"
                            ? "text-black dark:text-orange-500 font-semibold"
                            : " "
                            }`}
                        >
                          {t("Price (Low To High)")}
                        </li>

                        <li
                          onClick={handleHighToLow}
                          className={`dark:text-gray-400 text-[16px] mb-3 ${activeListItem === "highToLow"
                            ? "text-black dark:text-orange-500 font-semibold"
                            : " "
                            }`}
                        >
                          {t("Price (High To Low)")}
                        </li>
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <hr className="w-40 ml-4 my-2" />
              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg dark:bg-orange-500 bg-green-100 px-4 py-2 text-left text-md font-medium text-black hover:bg-green-200 dark:text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>{t("Availability")}</span>
                      <IoChevronUpCircleOutline
                        className={`${open ? "rotate-180 transform" : ""
                          } h-5 w-5 dark:text-white text-orange-500 font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="rounded-b-2xl px-4 pt-4 pb-2 dark:bg-secondary text-sm text-gray-500">
                      <ul className="text-md cursor-pointer">
                        <li
                          onClick={handleInStock}
                          className={`dark:text-gray-400 text-[16px] mb-3 ${activeListItem === "inStock"
                            ? "text-black dark:text-orange-500 font-semibold"
                            : " "
                            }`}
                        >
                          {t("In Stock")}
                        </li>

                        <li
                          onClick={handleOutOfStock}
                          className={`dark:text-gray-400 text-[16px] mb-3 ${activeListItem === "outOfStock"
                            ? "text-black dark:text-orange-500 font-semibold"
                            : " "
                            }`}
                        >
                          {t("Out of Stock")}
                        </li>
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
        <div className="md:col-span-3 lg:col-span-4 2xl:col-span-5">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mt-12 justify-center gap-8 ">
            {dataToRender?.map((item, index) => (
              <div
                key={index}
                className="card card-compact mx-auto w-full mb-3 bg-white dark:bg-secondary shadow-xl"
              >
                {/* localStorage.getItem("user-id") */}
                {localStorage.getItem("user-id") ? (
                  favorites?.some(
                    (favorite) => favorite.itemId === item._id
                  ) ? (
                    <AiFillHeart
                      onClick={() => removeFromFavorites(item)}
                      className="dark:text-red-500 text-black cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"
                    ></AiFillHeart>
                  ) : (
                    <AiOutlineHeart
                      onClick={() => addToFavorites(item)}
                      className="text-orange-500 cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"
                    ></AiOutlineHeart>
                  )
                ) : (
                  <Link to={"/signIn"} state={{ from: location }} replace>
                    <AiOutlineHeart className="text-orange-500 cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"></AiOutlineHeart>
                  </Link>
                )}
                {/* 
                {favorites?.some((favorite) => favorite.itemId === item._id) ? (
                  <AiFillHeart
                    onClick={() => removeFromFavorites(item)}
                    className="dark:text-red-500 text-black cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"
                  ></AiFillHeart>
                ) : (
                  <AiOutlineHeart
                    onClick={() => addToFavorites(item)}
                    className="text-orange-500 cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"
                  ></AiOutlineHeart>
                )} */}

                <Link
                  to={`/categoryItems/${item?._id}`}
                  onClick={() => handleItemClick(item)}
                >
                  <div className="px-2 py-4 space-y-4">
                    <h2
                      className="card-title text-[black] dark:text-white
     mt-5 text-sm sm:text-lg md:text-xl lg:text-xl xl:text-xl"
                    >
                      <div
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {item?._doc?.itemName || item?.itemName}
                      </div>
                    </h2>
                    {item?.availability === true ? (
                      <p className="bg-green-600 absolute left-4 px-2 -top-8 font-primary rounded-lg text-white p-1">
                        {t("In Stock")}
                      </p>
                    ) : (
                      <p className="bg-red-600 absolute left-4 px-2 -top-8 font-primary rounded-lg text-white p-1">
                        {t("Out Of Stock")}
                      </p>
                    )}
                    <div className="flex items-center space-x-2">
                      <h4 className="text-sm  mt-2">
                      {item.rating ? parseFloat(item.rating).toFixed(1) : "4.0"}
                      </h4>
                      <Rating
                        rating={item.rating ? parseFloat(item.rating) : 4}
                        size={14}
                      />
                      <h4 className="text-sm  mt-2">
                        ({item.ratedBy ?? 7})
                      </h4>
                    </div>
                    <div className="w-full xs:w-[185px]  sm:w-[270px] md:w-[250px] lg:w-[200px] xl:w-[220px] mx-auto">
                      <Swiper
                        pagination={{
                          dynamicBullets: true,
                        }}
                        autoplay={{
                          delay: 1500,
                          disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                      >
                        {item?.imageGallery?.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={process.env.REACT_APP_BACKEND_URL + '/' + image}
                              // src={image}
                              alt={"item"}
                              className="max-w-sm w-full rounded-lg shadow-2xl mx-auto"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>

                    <div className="text-2xl text-center items-center flex justify-center gap-1 sm:gap-2 ">
                      <div className="flex justify-center items-center text-[9px] xs:text-[11px] sm:text-[22px]">
                        <div className="font-semibold">
                          ₹ {item?.discPrice}{" "}
                        </div>
                      </div>
                      <div className="flex justify-center text-[8px] xs:text-[10px] sm:text-[20px] line-through items-center">
                        <div className="font-semibold">₹ {item?.price} </div>
                      </div>
                      <div className="text-[9px] xs:text-[10px] sm:text-[20px] text-orange-500 font-semibold">
                        (
                        {(
                          ((item?.price - item?.discPrice) / item?.price) *
                          100
                        ).toFixed(2)}
                        % off )
                      </div>
                    </div>
                  </div>
                </Link>
                {item?.availability === false && (
                  <div className="mb-4 px-1">
                    <button
                      onClick={() => openNotifyModal(item)}
                      disabled={notificationSent}
                      className="btn gap-2 btn-block btn-sm btn-warning hover:bg-orange-500 rounded-full bg-orange-600 text-white"
                    >
                      <MdOutlineNotificationsActive className="text-2xl"></MdOutlineNotificationsActive>
                      Notify Me
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div>
            <div className="join grid gap-2 grid-cols-2 rounded-md w-1/2 md:w-1/4 lg:w-1/6 ml-auto mt-5 pagination">
              <button
                className="join-item btn btn-warning text-white bg-orange-500"
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                Previous page
              </button>
              <button
                className="join-item btn btn-success bg-green-500 text-white"
                onClick={() => {
                  if (endIndex < selectedCategoryItems?.length) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                Next Page
              </button>
            </div>
          </div>
        </div>
      </div>
      <NotifyModal
        isOpen={isNotifyModalOpen}
        setIsOpen={setIsNotifyModalOpen}
        item={itemToNotify}
        setNotificationSent={setNotificationSent}
      ></NotifyModal>
      <div className="mt-8">
        <ArticleAds></ArticleAds>
      </div>
    </div>
  );
};

export default CategoryItemsCard;
