function Rating({ rating, setRating, size }) {
    return (
      <div>
        {[1, 2, 3, 4, 5].map((star, index) => {
          const isFullStar = rating >= star; 
          const isHalfStar =
            rating > star - 1 && 
            rating < star && 
            Math.floor(rating) + 0.3 <= rating; 
  
          return (
            <span
              key={index}
              className="star"
              style={{
                display: 'inline-block',
                cursor: 'pointer',
                fontSize: size ? `${size}px` : '14px',
                position: 'relative',
                color: isFullStar ? '#D78B30' : 'gray',
              }}
              onClick={() => setRating(star)}
            >
              {isFullStar ? (
                '★'
              ) : isHalfStar ? (
                <span style={{ position: 'relative', display: 'inline-block' }}>
                  <span
                    style={{
                      color: '#D78B30',
                      width: '50%',
                      overflow: 'hidden',
                      position: 'absolute',
                      display: 'inline-block',
                    }}
                  >
                    ★
                  </span>
                  <span style={{ color: 'gray' }}>★</span>
                </span>
              ) : (
                '★' 
              )}
            </span>
          );
        })}
      </div>
    );
  }
  
  
  export default Rating;
  