import { Link, useLocation, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./itemDetails.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FaPhoneAlt, FaRupeeSign } from "react-icons/fa";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineRightCircle,
} from "react-icons/ai";
import ShareButtons from "./ShareButtons";
import { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../Shared/Loading";
import { MdOutlineNotificationsActive } from "react-icons/md";
import NotifyModal from "../../Modals/NotifyModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase.init";
import useProfile from "../../../Hooks/useProfile";
import { toast } from "react-toastify";
import useCart from "../../../Hooks/useCart";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Loader from "../../Shared/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import BharatAds from "../../../GoogleAdSense/BharatAds";
import Rating from "../../../Components/Rating/rating";
// import ReactGA from 'react-ga';

const ItemDetails = () => {
  const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const [carts] = useCart();
  const location = useLocation();
  const { itemId } = useParams();
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [itemToNotify, setItemToNotify] = useState(null);
  const [notificationSent, setNotificationSent] = useState(false);
  const [profileData] = useProfile();
  const [, refetch] = useCart();
  const [favorites, setFavorites] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  // console.log(relatedProducts);

  function openNotifyModal(item) {
    setIsNotifyModalOpen(true);
    setItemToNotify(item);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  // Load favorites from local storage when the component mounts
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites"));
    if (storedFavorites) {
      setFavorites(storedFavorites);
    }
  }, []);

  const urlItem = `${process.env.REACT_APP_BACKEND_URL}/items/${itemId}?lang=${lang}`;
  const { data: item, isLoading } = useQuery(["item", itemId, lang], () =>
    axios.get(urlItem).then((response) => response.data)
  );

  const urlItems = `${process.env.REACT_APP_BACKEND_URL}/items?lang=${lang}`;
  const { data: relatedProductsData, isLoading: relatedProductsLoading } =
    useQuery(["relatedProducts", lang], () =>
      axios.get(urlItems).then((response) => response.data)
    );

  useEffect(() => {
    if (relatedProductsData && item) {
      // Filter related products based on the category of the current item
      const filteredProducts = relatedProductsData.filter((product) => {
        return (
          product?.category === item?.category && product?._id !== item?._id
        );
      });

      setRelatedProducts(filteredProducts);
    }
  }, [relatedProductsData, item]);

  // Function to increment visited count
  const incrementVisitedCount = async (itemId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/items/${itemId}/popular-items`
      );
    } catch (error) {
      console.error("Error while incrementing visited count:", error);
    }
  };

  useEffect(() => {
    // To Check if the item has an ID and is not undefined
    if (item && item._id) {
      const hasVisited = localStorage.getItem(`visited_${item._id}`);
      if (!hasVisited) {
        incrementVisitedCount(item._id);
        localStorage.setItem(`visited_${item._id}`, "true");
      }
    }
  }, [item]);

  const url = window.location.href;
  const message = `Check out this item: ${item?.itemName}\nPrice: ${item?.discPrice}`;

  const today = new Date();
  const deliveryDays = 4;
  const deliveryStartDate = new Date(
    today.getTime() + deliveryDays * 24 * 60 * 60 * 1000
  );
  const deliveryEndDate = new Date(
    deliveryStartDate.getTime() + 2 * 24 * 60 * 60 * 1000
  );
  const formattedDeliveryStartDate = deliveryStartDate.toDateString();
  const formattedDeliveryEndDate = deliveryEndDate.toDateString();

  const handleAddToCart = (item) => {
    const cartItem = {
      userId: user?.uid,
      userName: profileData?.name,
      userMobile: profileData?.mobileNumber,
      itemName: item?.itemName,
      itemId: item?._id,
      itemDiscPrice: parseInt(item?.discPrice),
      itemPrice: parseInt(item?.price),
      itemCategory: item?.category,
      itemImage: process.env.REACT_APP_BACKEND_URL + item?.imageGallery[0]
      // itemImage: item?.imageGallery[0],
    };

    // Check if the item with the same itemId already exists in the cart
    const existingCartItem = carts?.find(
      (cart) => cart?.itemId === cartItem?.itemId
    );

    if (existingCartItem) {
      // Item already exists, update the quantity or any other relevant information
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/carts/${existingCartItem?._id}`,
          {
            quantity: existingCartItem?.quantity + 1, // You can update other fields if needed
          }
        )
        .then((res) => {
          console.log(res?.data);
          refetch();
          toast(t("Product Added to Cart"));
        })
        .catch((error) => {
          console.error(error);
          // Handle the error as needed
        });
    } else {
      // Item doesn't exist, add it to the cart
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/carts`, cartItem)
        .then((res) => {
          console.log(res?.data);
          refetch();
          toast(t("Product Added to Cart"));
        })
        .catch((error) => {
          console.error(error);
          // Handle the error as needed
        });
    }
  };

  const handleBuyNowClick = (item) => {
    window.gtag("event", "Click", {
      event_category: "Buy Button Click",
      event_label: item?.itemName,
    });
    // ReactGA.event({
    //   category: 'Buy Button Click',
    //   action: 'Click',
    //   label: item?.itemName,
    // });
  };

  const addToFavorites = async (item) => {
    if (!favorites.some((favorite) => favorite?.itemId === item?._id)) {
      const itemObject = {
        itemId: item?._id,
        itemName: item?.itemName || "",
        imageGallery: item?.imageGallery || [],
        discPrice: item?.discPrice || 0,
        price: item?.price || 0,
        availability: item?.availability || true,
      };

      // Update local storage
      const updatedFavorites = [...favorites, itemObject];
      setFavorites(updatedFavorites);
      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
      toast.success(t("Item Added To Favourites"));

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/favourites`,
          {
            userName: profileData?.name,
            mobileNumber: profileData?.mobileNumber,
            items: [itemObject],
          }
        );
        if (res.status === 201) {
          // Handle the successful server-side addition if needed
        }
      } catch (error) {
        console.error("Error adding item to server favorites:", error);
      }
    }
  };

  const removeFromFavorites = async (item) => {
    const mobileNumber = profileData?.mobileNumber;

    // Update local storage
    const updatedFavorites = favorites?.filter(
      (favorite) => favorite.itemId !== item._id
    );
    setFavorites(updatedFavorites);
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    toast.warning(t("Item Removed To Favourites"));

    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/favourites/${mobileNumber}/${item._id}`
      );

      if (res?.status === 204) {
        // Handle the successful server-side removal if needed
      }
    } catch (error) {
      console.error("Error removing item from server favorites:", error);
    }
  };

  const handleItemClick = (item) => {
    // Track the item click event
    window.gtag("event", "Click", {
      event_category: "Item Click",
      event_label: item?.itemName,
    });
    // ReactGA.event({
    //   category: "Item Click",
    //   action: "Click",
    //   label: item?.itemName,
    // });
  };

  if (isLoading) {
    return <Loading></Loading>;
  }

  // console.log(item)

  return (
    <div className="md:py-12 ">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Product Details</title>
        <meta
          name="description"
          content={`Explore the ${item?.itemName}, a ${item?.category} available at Bharat Calendar Bhakthi Shop. Learn more about its features, specifications, and pricing. Find the perfect ${item?.itemName} to meet your needs and style.`}
        />
      </Helmet>
      <div className="hero w-full md:w-11/12 mx-auto rounded-xl bg-base-200 dark:bg-secondary px-10">
        <div className="grid md:grid-cols-3 md:gap-24 items-start py-10">
          <div className="md:col-span-1 mx-auto w-60 xs:w-[400px] sm:w-full ">
            {/* <img
              src={item?.img}
              alt={"item"}
              className="max-w-sm rounded-lg shadow-2xl w-full "
            /> */}
            <Carousel>
              {item?.imageGallery?.map((image, index) => (
                <div key={index}>
                  <img
                    src={process.env.REACT_APP_BACKEND_URL + '/' + image}
                    // src={image}
                    alt={"product"}
                    className="rounded-lg shadow-2xl w-full"
                  />
                </div>
              ))}
            </Carousel>
          </div>

          <div className="md:col-span-2">
            <div className="bg-green-600 rounded-full flex justify-center p-1 w-[240px] items-center mb-8 font-semibold text-white">
              <p>{t("YOU SAVE")} : </p>{" "}
              <span className="ml-1">
                <FaRupeeSign></FaRupeeSign>
              </span>{" "}
              <span className="mr-1"> {item?.price - item?.discPrice}</span> (
              <span>
                {(
                  ((item?.price - item?.discPrice) / item?.price) *
                  100
                ).toFixed(2)}
                %
              </span>
              )
            </div>
            <h1 className="text-4xl font-bold">{item?._doc?.itemName || item?.itemName}</h1>
            <div className="flex items-center space-x-2 mt-2">
              <h4 className="text-sm  mt-2">
              {item.rating ? parseFloat(item.rating).toFixed(1) : "4.0"}
              </h4>
              <Rating
                rating={item.rating ? parseFloat(item.rating) : 4}
                size={14}
              />
              <h4 className="text-sm  mt-2">
                ({item.ratedBy ?? 7})
              </h4>
            </div>
            <div className="py-5 font-bold text-xl md:flex items-center gap-4">
              {t("Price")}:{" "}
              <span className="text-orange-500 dark:text-white flex items-center">
                <FaRupeeSign></FaRupeeSign>
                {item?.discPrice}
              </span>
              <span className="text-black dark:text-white flex text-lg items-center line-through">
                <FaRupeeSign></FaRupeeSign>
                {item?.price}
              </span>{" "}
              {t("Inclusive of all taxes")}
            </div>

            <div className="flex gap-2 items-center bg-gray-300 dark:bg-gray-600 text-sm p-1 mb-3 w-full md:w-8/12">
              <div>
                <AiOutlineRightCircle className="text-xl text-orange-500"></AiOutlineRightCircle>
              </div>
              <div>
                {t("Note")}:{" "}
                {t("Actual Product may vary slightly from this image")}
              </div>
            </div>

            <div className="flex gap-2 items-center bg-gray-300 dark:bg-gray-600 text-sm p-1 mb-3 w-full md:w-8/12">
              <div>
                <AiOutlineRightCircle className="text-xl text-orange-500"></AiOutlineRightCircle>
              </div>
              <div>
                {t("Cash on Delivery Available (+ ₹50 Shipping Charges Extra)")}
              </div>
            </div>

            <div className="flex gap-2 items-center bg-gray-300 dark:bg-gray-600 text-sm p-1 mb-3 w-full md:w-8/12">
              <div>
                <AiOutlineRightCircle className="text-xl text-orange-500"></AiOutlineRightCircle>
              </div>
              <div>
                Delivery : {formattedDeliveryStartDate} -{" "}
                {formattedDeliveryEndDate}
              </div>
            </div>

            <div className="flex items-center gap-3 my-5">
              <p className="text-lg">{t("Share this product")}:</p>
              <div className="flex items-center gap-5">
                <ShareButtons url={url} message={message}></ShareButtons>
              </div>
            </div>
            <div className="w-full flex justify-start items-center gap-2 text-justify text-lg">
              <div>
                {t("Availability ")}:{" "}
                {item?.availability === true ? (
                  <span className="text-green-600 font-semibold">
                    {t("In Stock")}
                  </span>
                ) : (
                  <span className="text-red-600 font-semibold">
                    {t("Out Of Stock")}
                  </span>
                )}
              </div>
            </div>
            <div className="pt-5 text-lg">
              <p>
                {t("Payment Method")} :{" "}
                <span className="font-bold">
                  {t(
                    "Cash on Delivery / Debit / Credit / ATM Card / Net Banking / Other UPI Apps"
                  )}
                </span>
              </p>
            </div>
            <div className="py-5 flex justify-start items-center gap-3">
              {item?.availability === false ? (
                <button
                  onClick={() => openNotifyModal(item)}
                  disabled={notificationSent}
                  className="btn btn-warning gap-2 bg-orange-500 font-bold text-white"
                >
                  <MdOutlineNotificationsActive className="text-2xl"></MdOutlineNotificationsActive>
                  {t("Notify Me")}
                </button>
              ) : localStorage.getItem("user-id") ? (
                <div className="flex justify-start gap-3">
                  <Link
                    state={item}
                    to={"/placeOrder"}
                    onClick={() => handleBuyNowClick(item)}
                  >
                    <button className="btn btn-warning bg-orange-500 text-white">
                      {t("Buy Now")}
                    </button>
                  </Link>
                  <Link>
                    <button
                      onClick={() => handleAddToCart(item)}
                      className="btn btn-warning hover:bg-green-400  bg-green-600 text-white"
                    >
                      {t("Add To Cart")}
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="flex justify-start gap-3">
                  <Link to={"/signIn"} state={{ from: location }} replace>
                    <button className="btn btn-warning bg-orange-500 text-white">
                      {t("Buy Now")}
                    </button>
                  </Link>
                  <Link to={"/signIn"} state={{ from: location }} replace>
                    <button className="btn btn-warning hover:bg-green-400  bg-green-600 text-white">
                      {t("Add To Cart")}
                    </button>
                  </Link>
                </div>
              )}
            </div>

            <div className="text-center sm:text-left">
              <Tabs selectedTabClassName="custom-selected-tab">
                <TabList className={"custom-tab-list"}>
                  <Tab className={"custom-tab"}>
                    <h2>Description</h2>
                  </Tab>
                  <Tab className={"custom-tab"}>About the Product</Tab>
                  <Tab className={"custom-tab"}>More Info</Tab>
                </TabList>

                <TabPanel className={"mt-4"}>
                  <h2>{item?._doc?.description || item?.description}</h2>
                </TabPanel>
                <TabPanel className={"mt-4"}>
                  <h2>{item?._doc?.about || item?.about}</h2>
                </TabPanel>
                <TabPanel className={"mt-4"}>
                  <ul>
                    <li className="flex items-center gap-2 mb-3">
                      <FaPhoneAlt></FaPhoneAlt>
                      <a href={`tel:+919535601936`} className="underline">
                        English : 9535601936
                      </a>
                    </li>
                    <li className="flex items-center gap-2 mb-3">
                      <FaPhoneAlt></FaPhoneAlt>
                      <a href={`tel:+919535601936`} className="underline">
                        हिन्दी : 9535601936
                      </a>
                    </li>
                    <li className="flex items-center gap-2 mb-3">
                      <FaPhoneAlt></FaPhoneAlt>
                      <a href={`tel:+919535601936`} className="underline">
                        ಕನ್ನಡ : 9535601936
                      </a>
                    </li>
                    <li className="flex items-center gap-2 mb-3">
                      <FaPhoneAlt></FaPhoneAlt>
                      <a href={`tel:+919535601936`} className="underline">
                        മലയാളം : 9535601936
                      </a>
                    </li>
                  </ul>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <div className="my-8">
        <BharatAds></BharatAds>
      </div>
      {relatedProductsLoading ? (
        <Loader></Loader>
      ) : relatedProducts?.length === 0 ? (
        <div className="w-full md:w-11/12 mx-auto mt-8">
          <h2 className="text-3xl font-bold">{`Related Products For ${item?.category}`}</h2>
          <p className="text-center mt-12">No Products To Show</p>
        </div>
      ) : (
        <div className="w-11/12 mx-auto mt-8">
          <h2 className="text-3xl font-bold">{`Related Products For ${item?.category}`}</h2>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 mt-16 justify-center gap-8 ">
            {relatedProducts?.map((item, index) => (
              <div
                key={index}
                className="card card-compact mx-auto w-full mb-3 bg-white dark:bg-secondary shadow-xl"
              >
                {/* localStorage.getItem("user-id") */}
                {localStorage.getItem("user-id") ? (
                  favorites?.some(
                    (favorite) => favorite.itemId === item._id
                  ) ? (
                    <AiFillHeart
                      onClick={() => removeFromFavorites(item)}
                      className="dark:text-red-500 text-black cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"
                    ></AiFillHeart>
                  ) : (
                    <AiOutlineHeart
                      onClick={() => addToFavorites(item)}
                      className="text-orange-500 cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"
                    ></AiOutlineHeart>
                  )
                ) : (
                  <Link to={"/signIn"} state={{ from: location }} replace>
                    <AiOutlineHeart className="text-orange-500 cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"></AiOutlineHeart>
                  </Link>
                )}

                <Link
                  to={`/categoryItems/${item?._id}`}
                  onClick={() => handleItemClick(item)}
                >
                  <div className="px-2 py-4 space-y-4">
                    <h2
                      className="card-title text-[black] dark:text-white
     mt-5 text-sm sm:text-lg md:text-xl lg:text-xl xl:text-xl"
                    >
                      <div
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {item?._doc?.itemName || item?.itemName}
                      </div>
                    </h2>
                    {item?.availability === true ? (
                      <p className="bg-green-600 absolute left-4 px-2 -top-8 font-primary rounded-lg text-white p-1">
                        {t("In Stock")}
                      </p>
                    ) : (
                      <p className="bg-red-600 absolute left-4 px-2 -top-8 font-primary rounded-lg text-white p-1">
                        {t("Out Of Stock")}
                      </p>
                    )}

                    <div className="w-full xs:w-[185px]  sm:w-[270px] md:w-[250px] lg:w-[200px] xl:w-[220px] mx-auto">
                      <Swiper
                        pagination={{
                          dynamicBullets: true,
                        }}
                        autoplay={{
                          delay: 1500,
                          disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                      >
                        {item?.imageGallery?.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={process.env.REACT_APP_BACKEND_URL + '/' + image}
                              // src={image}
                              alt={"item"}
                              className="max-w-sm w-full rounded-lg shadow-2xl mx-auto"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>

                    <div className="text-2xl text-center items-center flex justify-center gap-1 sm:gap-2 ">
                      <div className="flex justify-center items-center text-[9px] xs:text-[11px] sm:text-[22px]">
                        <div className="font-semibold">
                          ₹ {item?.discPrice}{" "}
                        </div>
                      </div>
                      <div className="flex justify-center text-[8px] xs:text-[10px] sm:text-[20px] line-through items-center">
                        <div className="font-semibold">₹ {item?.price} </div>
                      </div>
                      <div className="text-[9px] xs:text-[10px] sm:text-[20px] text-orange-500 font-semibold">
                        (
                        {(
                          ((item?.price - item?.discPrice) / item?.price) *
                          100
                        ).toFixed(2)}
                        % off )
                      </div>
                    </div>
                  </div>
                </Link>
                {item?.availability === false && (
                  <div className="mb-4 px-1">
                    <button
                      onClick={() => openNotifyModal(item)}
                      disabled={notificationSent}
                      className="btn gap-2 btn-block btn-sm btn-warning hover:bg-orange-500 rounded-full bg-orange-600 text-white"
                    >
                      <MdOutlineNotificationsActive className="text-2xl"></MdOutlineNotificationsActive>
                      Notify Me
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <NotifyModal
        isOpen={isNotifyModalOpen}
        setIsOpen={setIsNotifyModalOpen}
        item={itemToNotify}
        setNotificationSent={setNotificationSent}
      ></NotifyModal>
    </div>
  );
};

export default ItemDetails;
